import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemeService } from 'console/app/core/services/themeService';
import { TooltipOptions } from 'highcharts';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { cloneDeep } from 'lodash';
import { AutocompleteOption } from './input.model';

const DEFAULT_MAX_LENGTH = 524288;

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements AfterViewInit, OnChanges {
    @Input() inputId: string = 'inputId';
    @Input() prependIcon: string;
    @Input() type: string = 'text';
    @Input() placeholder: string = '';
    @Input() label: string = '';
    @Input() isShowLabel: boolean = true;
    @Input() required: boolean = false;
    @Input() alwaysShowRequiredIndicator: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() isClickable: boolean = false;
    @Input() isObfuscated: boolean = false;
    @Input() translate: boolean = true;
    @Input() value: string;
    @Input() autofocus: boolean = false;
    @Input() autocompleteOptions: AutocompleteOption[];
    @Input() showErrors: boolean = true;
    @Input() errorClass: string;
    @Input() readonly: boolean;
    @Input() tooltip: TooltipOptions;
    @Input() dark: boolean = false; // DK_GRAY background
    @Input() light: boolean = false; // WHITE background
    @Input() appendIcon: string;
    @Input() acceptableFileTypes: string = '';
    @Input() prefix: string;
    @Input() showPrefix: boolean = true;
    @Input() maxLength: number = DEFAULT_MAX_LENGTH;
    @Output() inputBlur: EventEmitter<void> = new EventEmitter();
    @Output() valueChanged: EventEmitter<string> = new EventEmitter();
    @Output() fileValueChanged: EventEmitter<File> = new EventEmitter();

    @ViewChild('input', { static: false }) inputElementRef: ElementRef;

    showDropDown: boolean;
    lightTheme: boolean;
    formControl: NgControl;
    displayValue: string; // untrimmed value
    countryCode: any;

    filteredAutocompleteOptions: AutocompleteOption[];

    constructor(
        private injector: Injector,
    ) {
        this.lightTheme = ThemeService.getLightTheme();
    }

    ngOnChanges(changes: SimpleChanges) {
        try { this.formControl = this.injector.get(NgControl); } catch (e) {
            console.error('InputComponent could not inject NgControl.', e);
        }
        if (changes.value?.currentValue) {
            this.sanitizeValue(changes.value.currentValue);
        }
    }

    ngAfterViewInit() {
        this.filteredAutocompleteOptions = this.autocompleteOptions?.slice(0, 5);
        if (this.autofocus) this.focus();
    }

    get valueDisplay(): string {
        if (this.isObfuscated) return '********';
        return this.displayValue?.toString().replace(this.prefix ? new RegExp(this.prefix, 'i') : '', '');
    }

    setValue(value: string) {
        this.sanitizeValue(`${this.prefix ?? ''}${value}`);

        this.filterAutocompleteOptions();
        if (this.onChange) this.onChange(this.value);
        this.valueChanged.emit(this.value);
    }

    filterAutocompleteOptions() {
        if (!this.autocompleteOptions?.length) return;
        if (!this.value || this.value === '') {
            this.filteredAutocompleteOptions = this.autocompleteOptions;
        } else {
            this.filteredAutocompleteOptions = this.autocompleteOptions.filter(x => this.value && x.value.includes(this.value.toLowerCase().trim()));
        }
        this.filteredAutocompleteOptions = this.filteredAutocompleteOptions.sort((a, b) => b.priority - a.priority).slice(0, 5);
    }

    blur() {
        this.sanitizeValue(this.value);
        this.onTouched();
        this.inputBlur.next();
    }

    focus() {
        if (this.inputElementRef) this.inputElementRef.nativeElement.focus();
        this.filterAutocompleteOptions();
        if (this.autocompleteOptions?.length) this.toggleDropDown(true);
    }

    toggleDropDown(show: boolean) {
        if (!show) {
            if (this.inputElementRef && document.activeElement !== this.inputElementRef.nativeElement) {
                this.showDropDown = show;
            }
        } else this.showDropDown = show;
    }

    selectAutocomplete(option: AutocompleteOption) {
        this.setValue(option.value);
        this.toggleDropDown(false);
    }

    setFileValue(file: File) {
        this.sanitizeValue(file?.name.trim());
        this.filterAutocompleteOptions();
        if (this.onChange) this.onChange(this.value);
        this.fileValueChanged.emit(file);
    }

    onCountryChange(country: any): void {
        this.countryCode = country?.iso2 ?? 'US';
        this.sanitizeValue(this.inputElementRef.nativeElement.value);
    }

    private sanitizeValue(value: string): void {
        this.value = value?.trim ? value?.trim() : value;
        this.displayValue = cloneDeep(this.value);
        if (this.type === 'phone') setTimeout(() => this.sanitizePhoneNumber());
    }

    private sanitizePhoneNumber(): void {
        if (!this.value) return;
        const parsed = this.tryParsePhoneNumber(this.value, this.countryCode?.toUpperCase() ?? 'US');
        if (parsed.isValid()) {
            this.value = parsed.formatInternational().replace(/[^0-9+]/g, '');
            this.displayValue = cloneDeep(this.value);
            setTimeout(() => this.inputElementRef?.nativeElement.dispatchEvent(new KeyboardEvent('keyup', { 'bubbles': true })));
        }
        if (this.onChange) this.onChange(this.value);
        this.valueChanged.emit(this.value);
    }

    private tryParsePhoneNumber(phone: string, defaultCountry: CountryCode): { isValid(): boolean, formatInternational(): string } {
        try {
            return parsePhoneNumber(phone, defaultCountry);
        } catch {
            return { isValid: () => false, formatInternational: () => '' };
        }
    }

    // Form control interface fns
    onChange = (x: string) => {};

    onTouched = () => {};

    async writeValue(key: string) {
        this.sanitizeValue(key);
    }

    registerOnChange(fn: (key: string) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control
}
