import { Utils } from 'weavix-shared/utils/utils';

export const DEFAULT_MIN_DISTANCE_FT = 3;
export const DEFAULT_MAX_DISTANCE_FT = 75;
export const DEFAULT_MIN_DISTANCE_M = 1;
export const DEFAULT_MAX_DISTANCE_M = 25;
export const DEFAULT_MIN_TIME = 1;
export const DEFAULT_MAX_TIME = 60;
export const DEFAULT_TIME_THRESHOLD = 15000;

export interface RangeSliderOptions {
    min: number;
    max: number;
    thresholds: number[];
    id?: string;
    disabled?: boolean;
}

export enum RangeSection {
    low = 'low',
    middle = 'middle',
    high = 'high',
}

export function getRangeSliderOptions(units: string, minFt: number, maxFt: number, minM: number, maxM: number, lowValue: number, highValue?: number): RangeSliderOptions {
    const min = units === 'm' ? minM : minFt;
    const max = units === 'm' ? maxM : maxFt;
    const convertedLow = Math.min(max, Math.max(min, Utils.distanceConversion(lowValue, 'm', units)));
    const convertedHigh = highValue
        ? Math.min(max, Math.max(min, Utils.distanceConversion(highValue, 'm', units)))
        : null;

    return {
        min,
        max,
        thresholds: convertedHigh ? [convertedLow, convertedHigh] : [convertedLow],
    };
}

export function getTimerSlider(min: number, max: number, value: number) {
    return {
        min,
        max,
        thresholds: [Math.min(max, Math.max(min, value / 1000))],
    };
}
