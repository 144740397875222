import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from 'console/app/core/services/themeService';
import { OpenDirection } from 'weavix-shared/models/core.model';
import { actionTypeToTranslationKey, BulkEdit, BulkEditAction, TableHeaderAction, TableHeaderOptions, TableRow } from 'weavix-shared/models/table.model';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { ButtonGroupOption } from '../../button-group/button-group.component';
import { TableService } from '../table.service';

@AutoUnsubscribe()
@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.scss'],
})

export class TableHeaderComponent implements OnInit {
    @Input() options: TableHeaderOptions;
    @Input() folderId: string;
    @Input() selectedKeys: {[key: string]: TableRow};
    @Input() selectedFolderKeys: {[key: string]: TableRow};
    @Output() bulkEditOutput: EventEmitter<BulkEdit> = new EventEmitter<BulkEdit>();
    @Output() bulkMoveOutput: EventEmitter<void> = new EventEmitter();

    actionTypeToTranslationKey = actionTypeToTranslationKey;

    bulkEdits: BulkEdit[] = [];
    openDirection = OpenDirection;

    loading: boolean = false;
    lightTheme: boolean;

    constructor(
        public tableService: TableService,
        private profileService: ProfileService,
        private translationService: TranslationService,
    ) {}

    ngOnInit() {
        this.loading = true;
        this.lightTheme = ThemeService.getLightTheme();
        this.loading = false;
        if (this.options.bulkEdits && this.options.bulkEdits.length) this.bulkEdits = this.options.bulkEdits;
    }

    getActionButtonText() {
        const selectedKeyCount = this.getSelectedKeyCount();
        const count = selectedKeyCount > 0 ? ` (${selectedKeyCount}) ${this.translationService.getImmediate('selected')}` : '';
        return `${this.translationService.getImmediate('table.actions')}${count}`;
    }

    getActionEditOptions(): ButtonGroupOption[] {
        const options: {display: string, onClick: () => void, icon?: string}[] = [];
        this.bulkEdits.forEach(edit => {
            if (this.hasBulkEditPermission(edit)) {
                options.push({
                    display: edit.title,
                    icon: edit.icon,
                    onClick: () => this.bulkEditOutput.emit(edit),
                });
            }
        });


        if (this.options.folderType && this.canBulkMove()) {
            options.push({
                display: 'table.move',
                icon: 'fas fa-grip-horizontal',
                onClick: () => this.bulkMoveOutput.emit(),
            });
        }
        return options;
    }

    private hasBulkEditPermission(edit: BulkEdit) {
        if (edit.action === BulkEditAction.delete) {
            return Object.values(this.selectedKeys).every(v => this.hasEditPermission(v.original, true));
        }
        if (edit.permission) {
            return Object.values(this.selectedKeys).every(v => typeof edit.permission === 'function'
                ? edit.permission(v.original)
                : this.hasEditPermission(v.original));
        }

        return true;
    }

    private canBulkMove() {
        return Object.values(this.selectedKeys).every(v => this.hasEditPermission(v.original));
    }

    hasPermission(action: TableHeaderAction): boolean {
        return this.profileService.hasAddPermission(action.permission, action.facilityId ?? this.options.facilityId, !!this.options.facilityIds, this.folderId);
    }

    private hasEditPermission(row, allFacilities = false) {
        const folderId = row.folderId;
        const facilityId = this.options.facilityId ?? (this.options.facilityIds ? this.options.facilityIds(row) : null);
        return this.profileService.hasFacilitiesPermission(
            this.options.editPermissionAction,
            facilityId, folderId, allFacilities,
        );
    }

    getSelectedKeyCount() {
        return Object.keys(this.selectedKeys).length + Object.keys(this.selectedFolderKeys).length;
    }

}
