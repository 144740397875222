<div class="wrapper" [ngClass]="{'light': lightTheme }">
    <ng-container *ngIf="(tableService.loading$ | async) === false">

        <app-icon *ngIf="isBackIconVisible"
            [faIcon]="'fas fa-chevron-left'"
            (click)="onBackIconClick()"
            matTooltip="{{ 'generics.back' | translate }}"
            class="icon back-icon">
        </app-icon>

        <h1 *ngIf="options?.isTitleVisible" class="title">{{ title }}</h1>

        <ng-container *ngFor="let a of options?.actions">
            <div *ngIf="hasPermission(a)" class="action-buttons">
                <button (click)="a.clickAction?.call(this)" [disabled]="a.disabled?.call()" class="action-button">
                    <div *ngIf="a.icon" class="icon-wrapper">
                        <app-icon [faIcon]="a.icon" class="icon"></app-icon>
                    </div>
                    <span>{{ actionTypeToTranslationKey[a.type] ?? '' | translate:{type: a.name} }}</span>
                </button>
            </div>
        </ng-container>

        <app-icon *ngIf="isPlusIconVisible"
            [faIcon]="'fas fa-plus-circle'"
            (click)="onPlusIconClick()"
            [matTooltip]="plusIconTooltip"
            class="icon plus-icon">
        </app-icon>

        <ng-container *ngIf="options?.search?.show">
            <app-input
                type="text"
                class="search-input"
                [icon]="options.search.icon"
                [placeholder]="options.search.placeholder"
                (valueChanged)="options.search.searchAction?.call(this, options.search.fields, $event)"
            ></app-input>
        </ng-container>

        <div *ngIf="options?.isPaginationInfoVisible" class="pagination-info">
            {{ 'table.header.pagination-info' | translate:(tableService.paginationInfo$ | async) }}
        </div>

        
        <!-- Bulk Actions -->
        <div class="bulk-actions">
            <app-button-group
                *ngIf="getSelectedKeyCount() > 0 && getActionEditOptions().length > 0"
                [hasPrimaryAction]="false"
                [buttonText]="getActionButtonText()"
                [class]="['pill']"
                [showOptions]="true"
                [options]="getActionEditOptions()"
                [openDirection]="openDirection.BottomLeft"
            ></app-button-group>
        </div>


        <button *ngIf="options?.import" 
            [matTooltip]="'table.header.import' | translate:{type: options.import.name}"
            class="import-button"
            (click)="options.import.action()"
        >
            <app-icon [faIcon]="'fas fa-file-import'" class="icon"></app-icon>
        </button>

    </ng-container>
</div>
