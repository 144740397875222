import { AnalyticsServiceBase, StProperties } from 'weavix-shared/services/analytics.service';

/// <reference path="../../../../../node_modules/@types/segment-analytics/index.d.ts" />
import { Injectable } from '@angular/core';

export enum StObject {
    AccountSwitcher = 'AccountSwitcher',
    AnonymousUser = 'AnonymousUser',
    ApiKey = 'ApiKey',
    Beacon = 'Beacon',
    Bulk = 'Bulk',
    Channel = 'Channel',
    ChannelMessage = 'ChannelMessage',
    ChannelNotificationSettings = 'ChannelNotificationSettings',
    ChannelTagFilter = 'ChannelTagFilter',
    Company = 'Company',
    Dashboard = 'Dashboard',
    DashboardWidget = 'DashboardWidget',
    Facility = 'Facility',
    Folder = 'Folder',
    Form = 'Form',
    Geofence = 'Geofence',
    GeofenceType = 'GeofenceType',
    FloorPlan = 'FloorPlan',
    Meeting = 'Meeting',
    MapPlaybackTimeSelection = 'MapPlaybackTimeSelection',
    MessageAttachment = 'MessageAttachment',
    PermissionGroup = 'PermissionGroup',
    Person = 'Person',
    Structure = 'Structure',
    TableBulkAction = 'TableBulkAction',
    TableRowAction = 'TableRowAction',
    Tag = 'Tag',
    UserAvatar = 'UserAvatar',
    UserProfile = 'UserProfile',
    WifiRouter = 'WifiRouter',
    WilmaDetailPtt = 'WilmaDetailPtt',
    WilmaDetailSiren = 'WilmaDetailSiren',
    WilmaDetailBan = 'WilmaDetailBan',
    WilmaWidgetExpand = 'WilmaWidgetExpand',
    WilmaWidgetMap = 'WilmaWidgetMap',
    WilmaWidgetSiren = 'WilmaWidgetSiren',
    Workflow = 'Workflow',
    Wrangler = 'Wrangler',
    Item = 'Item',
}

export enum StAction {
    Admitted = 'Admitted',
    Canceled = 'Canceled',
    Cleared = 'Cleared',
    Clicked = 'Clicked',
    Copied = 'Copied',
    Created = 'Created',
    Deleted = 'Deleted',
    Disabled = 'Disabled',
    Enabled = 'Enabled',
    Exported = 'Exported',
    Imported = 'Imported',
    Joined = 'Joined',
    Left = 'Left',
    Messaged = 'Messaged',
    Moved = 'Moved',
    Rejected = 'Rejected',
    Removed = 'Removed',
    Resized = 'Resized',
    Set = 'Set',
    Subscribed = 'Subscribed',
    Toggled = 'Toggled',
    Unsubscribed = 'Unsubscribed',
    Updated = 'Updated',
    Uploaded = 'Uploaded',
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService extends AnalyticsServiceBase {
    static track(object: StObject, action: StAction, component: string, data: StProperties = {}): void {
        super.track(object, action, component, data);
    }
}
