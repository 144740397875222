export enum ConfigurationPageRouteParamKey {
    DashboardId = 'dashboardId',
    FacilityId = 'facilityId',
    FormId = 'formId',
    UserId = 'userId',
    PendingPersonId = 'pendingPersonId',
    RequestedPersonId = 'requestedPersonId',
    ImportedPersonId = 'importedPersonId',
    WorkflowId = 'workflowId',
    ApiKeyId = 'apiKeyId',
    GeofenceTypeId = 'geofenceTypeId',
    PowerBiReportId = 'powerBiReportId',
    ChannelId = 'channelId',
    CraftId = 'craftId',
    AlertTypeId = 'alertTypeId',
    ItemTypeId = 'itemTypeId',
    SelfRegistrationId = 'selfRegistrationId',
    PermissionGroupId = 'permissionGroupId',
}
