import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
    @Output() dropItem = new EventEmitter<FileList>();
    private dragCounter: number;

    constructor() {
        this.dragCounter = 0;
    }

    @HostBinding('class.dragging') active = false;

    @HostListener('drop', ['$event']) onDrop($event: any) {
        if ($event?.preventDefault) {
            $event?.preventDefault();
        }
        if ($event?.dataTransfer?.files) {
            this.dropItem.emit($event?.dataTransfer?.files);
            this.dragCounter = 0;
            this.active = false;
        }
    }

    @HostListener('dragover', ['$event']) onDragOver($event: DragEvent) {
        $event.preventDefault();
        this.active = true;
    }

    @HostListener('dragenter', ['$event']) onDragEnter($event: DragEvent) {
        $event.preventDefault();
        this.dragCounter++;
        this.active = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave($event: any) {
        $event.preventDefault();
        this.dragCounter--;
        this.active = this.dragCounter !== 0;
    }
}
