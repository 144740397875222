import { Route } from '@angular/router';
import { ConfigurationPageRouteParamKey } from '../../console/app/console/configuration/enums/configuration-page-route-param-key.enum';
import { PowerBiComponent } from '../../console/app/console/power-bi/power-bi.component';
import { permissionTargetActionGuardFn } from '../../console/app/core/guards/permission-target-action.guard';
import { DashboardComponent } from '../../console/app/shared/dashboard/dashboard.component';
import { PermissionAction } from '../permissions/permissions.model';

export enum AppRoute {
    Add = 'add',
    Audio = 'audio',
    Alerts = 'alerts',
    AlertTypes = 'alert-types',
    ApiKeys = 'api-keys',
    Channels = 'channels',
    Chat = 'chat',
    Companies = 'companies',
    Compliance = 'compliance',
    Configuration = 'configuration',
    Crafts = 'crafts',
    Dashboard = 'dashboard',
    Dashboards = 'dashboards',
    DeviceManagement = 'device-management',
    Equipment = 'equipment',
    Facilities = 'facilities',
    Forms = 'forms',
    GeofenceTypes = 'geofence-types',
    General = 'general',
    HoleWatch = 'hole-watch',
    Import = 'import',
    Items = 'items',
    ItemTypes = 'item-types',
    LiveLook = 'live-look',
    Map = 'map',
    NoAccess = 'no-access',
    WorkerEfficiency = 'worker-efficiency',
    PowerBiReport = 'report',
    PowerBiConfig = 'report-config',
    People = 'people',
    PermissionGroups = 'permission-groups',
    Profile = 'profile',
    Radio = 'radio',
    Safety = 'safety',
    SelfRegistration = 'self-register',
    Sites = 'facilities',
    SmartRadio = 'smart-radio',
    Video = 'video',
    Users = 'users',
    UsersPending = 'pending',
    UsersRequested = 'requested',
    UsersBlocked = 'blocked',
    UsersImported = 'imported',
    UserIncomplete = 'incomplete',
    WaltSettings = 'walt-settings',
    WheresWalt = 'wheres-walt',
    Workflows = 'workflows',
}

// site specific permissions do not work for these
export const ACCOUNT_ONLY_PERMISSIONS = [
    PermissionAction.ViewAccount,
    PermissionAction.ViewAdministratorDefinedChannels,
    PermissionAction.ViewAlertTypes,
    PermissionAction.ViewApiKeys,
    PermissionAction.ViewCrafts,
    PermissionAction.ViewDashboards,
    PermissionAction.ViewForms,
    PermissionAction.ViewGeofenceTypes,
    PermissionAction.ViewItemTypes,
    PermissionAction.ViewPermissions,
    PermissionAction.ViewSites,
    PermissionAction.ViewWorkflows,
    PermissionAction.EditAccount,
    PermissionAction.EditAdministratorDefinedChannels,
    PermissionAction.EditAlertTypes,
    PermissionAction.EditApiKeys,
    PermissionAction.EditCrafts,
    PermissionAction.EditDashboards,
    PermissionAction.EditForms,
    PermissionAction.EditGeofenceTypes,
    PermissionAction.EditItemTypes,
    PermissionAction.EditPermissions,
    PermissionAction.EditSites,
    PermissionAction.EditWorkflows,
    PermissionAction.UseDeviceManagement,
    PermissionAction.ViewCompliance,
];

// these items are associated with potentially multiple sites which can give permission to items individually
export const MULTI_SITE_PERMISSIONS = [
    PermissionAction.ViewPeople,
    PermissionAction.EditPeople,
];

// someone must have all of the specified permissions in order to access the given route
export const ROUTE_PERMISSIONS: {[key in AppRoute]?: PermissionAction[]} = {
    [AppRoute.Alerts]: [ PermissionAction.SendAlerts ],
    [AppRoute.AlertTypes]: [ PermissionAction.ViewAlertTypes ],
    [AppRoute.Audio]: [],
    [AppRoute.Chat]: [],
    [AppRoute.Configuration]: [
        PermissionAction.ViewAccount,
        PermissionAction.ViewAdministratorDefinedChannels,
        PermissionAction.ViewAlertTypes,
        PermissionAction.ViewApiKeys,
        PermissionAction.ViewCrafts,
        PermissionAction.ViewDashboards,
        PermissionAction.ViewForms,
        PermissionAction.ViewGeofenceTypes,
        PermissionAction.ViewItemTypes,
        PermissionAction.ViewPermissions,
        PermissionAction.ViewSites,
        PermissionAction.ViewPeople,
        PermissionAction.ViewWorkflows,
    ],
    [AppRoute.Equipment]: [ PermissionAction.ViewEquipmentLiveLook ],
    [AppRoute.HoleWatch]: [ PermissionAction.ViewConfinedSpaces ],
    [AppRoute.LiveLook]: [ PermissionAction.ViewWorkerLiveLook ],
    [AppRoute.Safety]: [ PermissionAction.SendAlerts, PermissionAction.ViewConfinedSpaces ],
    [AppRoute.WorkerEfficiency]: [ PermissionAction.ViewWorkerEfficiency ],
    [AppRoute.People]: [ PermissionAction.ViewWorkerLiveLook, PermissionAction.ViewWorkerEfficiency ],
    [AppRoute.SmartRadio]: [ PermissionAction.UseDeviceManagement ],
    [AppRoute.Sites]: [ PermissionAction.ViewSites ],
    [AppRoute.Users]: [ PermissionAction.ViewPeople ],
    [AppRoute.Video]: [],
    [AppRoute.Workflows]: [ PermissionAction.ViewWorkflows ],
    [AppRoute.Dashboards]: [ PermissionAction.ViewDashboards ],
    [AppRoute.Dashboard]: [ PermissionAction.ViewDashboards ],
    [AppRoute.PowerBiConfig]: [ PermissionAction.ViewDashboards ],
    [AppRoute.PowerBiReport]: [ PermissionAction.ViewDashboards ],
    [AppRoute.Forms]: [ PermissionAction.ViewForms ],
    [AppRoute.ApiKeys]: [ PermissionAction.ViewApiKeys ],
    [AppRoute.General]: [ PermissionAction.ViewAccount ],
    [AppRoute.Channels]: [ PermissionAction.ViewAdministratorDefinedChannels ],
    [AppRoute.Crafts]: [ PermissionAction.ViewCrafts ],
    [AppRoute.GeofenceTypes]: [ PermissionAction.ViewGeofenceTypes ],
    [AppRoute.ItemTypes]: [ PermissionAction.ViewItemTypes ],
    [AppRoute.SelfRegistration]: [ PermissionAction.ViewRegistrationCodes ],
    [AppRoute.WaltSettings]: [ PermissionAction.EditWaltSettings ],
    [AppRoute.PermissionGroups]: [ PermissionAction.ViewPermissions ],
    [AppRoute.DeviceManagement]: [ PermissionAction.UseDeviceManagement ],
    [AppRoute.Compliance]: [ PermissionAction.ViewCompliance ],
};

export const DASHBOARD_ROUTE: Route = {
    path: `${AppRoute.Dashboard}/:${ConfigurationPageRouteParamKey.DashboardId}`,
    component: DashboardComponent,
    canActivate: [permissionTargetActionGuardFn],
    data: { permissions: ROUTE_PERMISSIONS[AppRoute.Dashboard] },
};

export const POWER_BI_REPORT_ROUTE: Route = {
    path: `${AppRoute.PowerBiReport}/:${ConfigurationPageRouteParamKey.PowerBiReportId}`,
    component: PowerBiComponent,
    canActivate: [permissionTargetActionGuardFn],
    data: { permissions: ROUTE_PERMISSIONS[AppRoute.PowerBiReport] },
};

export const DYNAMIC_ROUTES: Route[] = [DASHBOARD_ROUTE, POWER_BI_REPORT_ROUTE];
