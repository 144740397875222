import { CardSelectTheme, CardType } from '../card-select/card-select.model';
import { AutocompleteOption } from '../input/input.model';

export interface DataFilterOptions {
    filters?: Filter[];
    searchOptions?: SearchOptions;
    collapsible?: boolean;
    theme?: CardSelectTheme;
}

export interface Filter {
    key: string;
    label: string;
    type: FilterType;
    multiSelect?: boolean;
    booleanOptions?: {
        trueLabel?: string;
        falseLabel?: string;
    };
}

export enum FilterType {
    Text = 'text',
    TextArray = 'textArray',
    Boolean = 'boolean',
    Tags = 'tags',
    Crafts = 'crafts',
    Company = 'company',
    Geofence = 'geofence',
    Walt = 'walt',
}

export enum SearchType {
    Text = 'text',
    TextArray = 'textArray',
    Tags = 'tags',
    Crafts = 'crafts',
}

export interface SearchOptions {
    searchFields: SearchField[];
    onChange?: (searchInput: string) => Promise<{ value: string; field: string; }>;
    searchAction?: {
        icon?: string;
        label?: string;
        action: (searchInput: string) => boolean;
        visible?: (search: string, filteredData: any[]) => boolean;
        enabled?: (search: string, filteredData: any[]) => boolean;
        searchActionCardType?: CardType;
    };
    autocompleteOptions?: AutocompleteOption[];
    inputPlaceholder?: string;
}

export interface SearchField {
    type: SearchType;
    field: string;
    requireExactMatch?: boolean;
}
