import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService } from '../../../../../weavix-shared/services/account.service';
import { HttpService } from '../../../../../weavix-shared/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuard {
    constructor(
        private accountService: AccountService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return this.checkIfAlreadyLoggedIn(route);
    }

    navigateTo(url: string): void {
        window.location.href = url;
    }

    private async checkIfAlreadyLoggedIn(route: ActivatedRouteSnapshot): Promise<boolean> {
        // Override unauthenticated here in case we are logging out to prevent 401 redirection within http.service
        const unauthenticated = HttpService.unauthenticated;
        HttpService.unauthenticated = () => {};

        try {
            const user = await this.accountService.getUser(null);
            if (user?.id) {
                HttpService.unauthenticated = unauthenticated;
                this.router.navigateByUrl('/');
                return false;
            }
        } catch {
            // noop
        }

        this.navigateTo(route.data['externalUrl']);
        return true;
    }
}

export const redirectGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(RedirectGuard).canActivate(route, state);
