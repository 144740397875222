import { Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TooltipOptions } from 'highcharts';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        },
    ],
})
export class DatePickerComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() tootip: TooltipOptions;
    @Input() translate: boolean = true;
    @Input() inputClass: string = '';
    @Input() required: boolean = false;
    @Input() readonly: boolean = false;
    @Input() autofocus: boolean = false;
    @Input() value: any; // Can be used to keep two inputs tied to the same form control in sync
    @Input() showErrors: boolean = true;
    @Input() floatLabel: boolean = true;
    @Input() max: Date = null;

    @Output() typing: EventEmitter<any> = new EventEmitter();
    @Output() blurElement: EventEmitter<void> = new EventEmitter();
    @Output() valueChanged: EventEmitter<Date> = new EventEmitter();

    @ViewChild('input', { static: true }) private inputElementRef: ElementRef;

    translatedLabel: string;

    formControl: NgControl;

    constructor(
        private translationService: TranslationService,
        private injector: Injector,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.required) {
            // required will be an empty string if the required attribute is present with no value set
            // It will be undefined if not present
            this.required = changes.required.currentValue === '' || changes.required.currentValue === true;
        }
        if (changes.autofocus && this.autofocus) {
            this.focus();
        }
    }

    async ngOnInit() {
        try { this.formControl = this.injector.get(NgControl); } catch (e) { 
            console.error('DatePickerComponent could not inject NgControl.', e);
        }
        if (this.label) {
            this.translatedLabel = this.translate ? await this.translationService.getTranslation(this.label) : this.label;
        }
        if (this.autofocus) this.focus();
    }

    focus() {
        this.inputElementRef.nativeElement.focus();
    }

    setValue(value: any) {
        this.value = value;
        if (this.onChange) this.onChange(value);
        this.valueChanged.emit(value);
    }

    // Form control interface fns
    onChange = (x: any) => {};

    onTouched = () => {};

    async writeValue(key: any) {
        this.value = key;
    }

    registerOnChange(fn: (key: any) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control
}
