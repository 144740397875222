import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DashboardSource, DashboardWidgetConfig, formatWidget, inferType, RichTextEditorSettings, WidgetClickAction } from 'weavix-shared/models/dashboard.model';
import { timeAgo } from 'weavix-shared/pipes/time-ago.pipe';
import { DashboardService } from 'weavix-shared/services/dashboard.service';
import { DataSourceResults, DataSourceService } from 'weavix-shared/services/data-source.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
  selector: 'app-rich-text-widget',
  templateUrl: './rich-text-widget.component.html',
  styleUrls: ['./rich-text-widget.component.scss'],
})
export class RichTextWidgetComponent implements OnInit {
    @Input() widgetData: DashboardWidgetConfig;
    @Input() sources: DashboardSource[];
    @Input() editable: boolean;
    @Output() tableView: EventEmitter<any> = new EventEmitter();
    @Output() dashboardView: EventEmitter<any> = new EventEmitter();

    rows: {[key: string]: any}[];
    processedText: string;
    style: any = {};

    results: DataSourceResults;
    isLoading: boolean;

    get settings() { return _.get(this.widgetData, 'options.settings') as RichTextEditorSettings; }
    get source() { return (this.sources || []).find(x => x.id === this.widgetData?.source)?.source; }
    get variables() { return this.widgetData?.variables ?? []; }
    get editorContent() { return _.get(this.settings, 'editorContent'); }

    constructor(
        private dataSourceService: DataSourceService,
        private translationService: TranslationService,
        private dashboardService: DashboardService,
    ) { }

    async ngOnInit() {
        if (this.source) await this.getSourceData();
        else this.processedText = this.editorContent;
    }

    private async getSourceData() {
        this.isLoading = true;
        this.dashboardService.setWidgetLoading(this, this.isLoading);
        (await this.dataSourceService.subscribeData(this, this.source, this.widgetData.variables)).subscribe(results => {
            if (results.isLoading) {
                this.isLoading = true;
                this.dashboardService.setWidgetLoading(this, this.isLoading);
                return;
            }
            this.isLoading = false;
            this.dashboardService.setWidgetLoading(this, this.isLoading);
            this.results = results;
            this.rows = results?.rows ? Object.values(results.rows) : null;
            this.processedText = this.proccessText(this.editorContent);

            const result = formatWidget(this.settings, this.rows);
            this.style = result[0] || {};
        });
    }

    private proccessText(editorContent: string): string {
        if (!editorContent) return editorContent;

        let processedText = editorContent;
        for (let i = processedText.indexOf('{{'); i !== -1;) {
            const end = processedText.indexOf('}}', i + 2);
            const variable = processedText.substring(i + 2, end);
            const replacement = this.processReplacement(variable);
            processedText = processedText.substring(0, i) + replacement + processedText.substring(end + 2);
            i = processedText.indexOf('{{', i + replacement.length);
        }

        return processedText;
    }

    private processReplacement(variable) {
        const optionParts = variable.split('|');
        const parts = optionParts[0];
        const val = inferType(this.rows[0]?.[parts]);
        if (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') return String(val);
        const ago = optionParts[1]?.toLowerCase()?.indexOf('ago') >= 0;
        if (val instanceof Date) {
            if (ago) {
                return timeAgo(val, this.translationService.getLanguage());
            }
            return moment(val).format(optionParts[1] || 'M/D/YY h:mm:ss a');
        }
        if (ago) return this.translationService.getImmediate('generics.never');
        return '';
    }

    private isAnchorTag(e: Event): boolean {
        const target = e.target as HTMLElement;
        const parentTarget = target.parentNode as HTMLElement;

        return target?.tagName?.toLowerCase() === 'a' || parentTarget?.tagName?.toLowerCase() === 'a';
    }


    async handleClick(e: Event) {
        if (this.editable || this.isAnchorTag(e)) return false;

        switch (this.settings.clickAction) {
            case WidgetClickAction.Dashboard: {
                if (!this.settings.drillDown) return;

                const row = Object.values(this.results.rows)[0];
                this.dashboardView.emit({ row, drillDown: this.settings.drillDown });

                break;
            }

            default: {
                let results;
                if (this.source.sql) {
                    results = await this.dataSourceService.runDrillDownSql(
                        this, this.source.sql,
                        null,
                        null,
                        this.variables);
                } else {
                    results = {
                        meta: {
                            columns: await this.dataSourceService.getColumnsFrom(this, this.source),
                        },
                        rows: this.results.allRows || this.results.rows,
                    };
                }

                this.tableView.emit({ rows: results.rows, meta: results.meta });

                break;
            }
        }
    }
}
