import { Injectable, inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ModalActionType, ModalService } from 'app/core/services/modal/modal.service';
import { ComponentCanDeactivate } from './deactivate.guard';

@Injectable({
    providedIn: 'root',
})
export class PendingChangesGuard {
    constructor(
        private modalService: ModalService,
    ) {}
    async canDeactivate(component: ComponentCanDeactivate) {
        if (!component.canDeactivate || component.canDeactivate()) {
            return true;
        } else {
            const modalResult = await this.modalService.confirmLeave();
            if (modalResult.action !== ModalActionType.submit) {
                return false;
            } else {
                return true;
            }
        }
    }
}

export const pendingChangesGuardFn: CanDeactivateFn<ComponentCanDeactivate> = (component: ComponentCanDeactivate) => inject(PendingChangesGuard).canDeactivate(component);
