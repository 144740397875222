import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-logo-spinner',
    templateUrl: './logo-spinner.component.html',
    styleUrls: ['./logo-spinner.component.scss'],
})
export class LogoSpinnerComponent {

    @Input() size: number;
    @Input() slow: boolean;

    constructor() { }
}
